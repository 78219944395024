export const response_3 = {
  id: 41,
  date: '2020-01-20T20:12:51',
  date_gmt: '2020-01-20T17:12:51',
  guid: {
    rendered: 'https://spasmalgon.ru/?post_type=about&#038;p=41',
  },
  modified: '2024-04-26T14:18:00',
  modified_gmt: '2024-04-26T11:18:00',
  slug: '3-%d1%81%d0%bb%d0%b0%d0%b9%d0%b4',
  status: 'publish',
  type: 'about',
  link: 'https://spasmalgon.teva.ru/about/3-%d1%81%d0%bb%d0%b0%d0%b9%d0%b4/',
  title: {
    rendered: '3 слайд',
  },
  content: {
    rendered: '',
    protected: false,
  },
  featured_media: 0,
  template: '',
  acf: {
    text: '<p><strong>Спазмалгон®</strong> принимается при болевом синдроме умеренной или слабой выраженности: головная, зубная боль, мигрень (приступообразная головная боль), невралгия, (боль походу нерва или его ветвей), миалгия (боли в мышцах), артралгия (боли в суставах), альгодисменорея (менструальные боли), боль при травмахи ожогах<sup>5</sup>. При необходимости, пожалуйста, проконсультируйтесь с врачом перед применением препарата.</p>',
    sup: '5.  Листок-вкладыш – информация для пациента Спазмалгон® ЛП-№(002098)-(РГ-RU)',
  },
  _links: {
    self: [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/about/41',
      },
    ],
    collection: [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/about',
      },
    ],
    about: [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/about',
      },
    ],
    'wp:attachment': [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=41',
      },
    ],
    curies: [
      {
        name: 'wp',
        href: 'https://api.w.org/{rel}',
        templated: true,
      },
    ],
  },
};
