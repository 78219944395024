<template>
  <div id="app">
    <div class="cart">
      <img class="cart__image" src="./assets/img/cart-icon.svg" alt="cart">
      <a class="cart__link" target="_blank"
         href="https://apteka.ru/preparation/spazmalgon/?utm_source=teva&utm_medium=buy_now&utm_campaign=spazmalgon">
        <img src="./assets/img/apteka.png" alt="apteka.ru">
      </a>
    </div>
    <div class="page"
         v-bind:class="{'page--about': about, 'page--new': newpage, 'page--questions': questions, 'page--info': info }">
      <div class="social">

        <transition name="flip">
          <div class="social__button gtm-smm" v-if="!social" @click="social = !social"></div>
        </transition>
        <transition name="flip">
          <div class="social__button_close" v-if="social" @click="social = !social"></div>
        </transition>
        <transition name="ups">
          <div class="social__content" v-if="social">
            <div class="social__vk gtm-vk"
                 @click="share_it('vk', 'https://spasmalgon.teva.ru/', 'Новый сайт и проверенная формула от боли и спазмов.', 'https://spasmalgon.teva.ru/share.jpg', ' ')">
              <a href="javascript:void(0)"></a>
            </div>
            <!-- <div class="social__fb gtm-fb" @click="share_it('fb', 'https://spasmalgon.teva.ru/', 'Новый сайт и проверенная формула от боли и спазмов.', 'https://spasmalgon.teva.ru/share.jpg', ' ')"><a href="javascript:void(0)"></a>
            </div> -->
            <div class="social__ok gtm-ok"
                 @click="share_it('ok', 'https://spasmalgon.teva.ru/', 'Новый сайт и проверенная формула от боли и спазмов.', 'https://spasmalgon.teva.ru/share.jpg', ' ')">
              <a href="javascript:void(0)"></a>
            </div>
          </div>
        </transition>
      </div>
      <transition name="zoom">
        <div class="main" v-if="mainStart">
          <transition name="zoomIn">
            <div>
              <div class="main__product" v-if="mainTitleStart"
                   :style="{ backgroundImage: `url('${main_image.acf.image}'+'?dii')` }"></div>
              <a v-if="main_image.acf.btn_buy" @click="open_modal_pharms()"
                 class="main__product-button">Где купить</a>
            </div>
          </transition>
        </div>
      </transition>
      <transition name="zoom">
        <div class="about" v-if="aboutStart" v-bind:class="{unzoom: questionsUnzoom}">
          <transition name="title">
            <div id="about_btn" class="about__title title gtm-about" @click="openAbout" v-if="aboutTitleStart">
              спазмалгон<sup>&reg;</sup>
            </div>
          </transition>
          <div class="about__text">
            <div class="swiper-container swiper-container-1">
              <div class="swiper-wrapper" ref="aboutSliderWrapper">
                <div class="swiper-slide">
                  <div class="about__inf">
                    <div v-bar class="vuebar-element">
                      <div>
                        <div v-html="about_slide_1.acf.text"></div>
                        <div class="about__products">
                          <div class="about__product about__product--1">
                            <div class="about__product-title">{{ about_slide_1.acf.title_1 }}</div>
                            <div class="about__product-image"><img loading="lazy"
                                                                   :src="about_slide_1.acf.image_1 + '?dii'" alt="">
                            </div>
                          </div>
                          <div class="about__product about__product--2">
                            <div class="about__product-title" v-html="about_slide_1.acf.title_2"></div>
                            <div class="about__product-image"><img loading="lazy"
                                                                   :src="about_slide_1.acf.image_2 + '?dii'" alt="">
                            </div>
                          </div>
                          <div class="about__product about__product--3">
                            <div class="about__product-info" v-html="about_slide_1.acf.label"></div>
                            <div class="about__product-title">{{ about_slide_1.acf.title_3 }}</div>
                            <div class="about__product-image"><img loading="lazy"
                                                                   :src="about_slide_1.acf.image_3 + '?dii'" alt="">
                            </div>
                          </div>
                        </div>
                        <span v-html="about_slide_1.acf.sup"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide slide--2">
                  <div class="about__inf">
                    <div v-bar class="vuebar-element">
                      <div>
                        <div v-html="about_slide_2.acf.text"></div>
                        <div class="about__icons">
                          <div class="about-icon">
                            <img src="./assets/img/icon-1.svg" alt="">
                            <i>{{ about_slide_2.acf.title_1 }}</i>
                          </div>
                          <div class="about-icon">
                            <img src="./assets/img/icon-2.svg" alt="">
                            <i v-html="about_slide_2.acf.title_2"></i>
                          </div>
                          <div class="about-icon">
                            <img src="./assets/img/icon-4.svg" alt="">
                            <i class="multirow" v-html="about_slide_2.acf.title_4"></i>
                          </div>
                        </div>
                        <span v-html="about_slide_2.acf.sup"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="about__inf">
                    <div v-bar class="vuebar-element">
                      <div>
                        <div v-html="about_slide_3.acf.text"></div>
                        <span v-html="about_slide_3.acf.sup"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination-1" v-if="about"></div>
          </div>
          <div class="about__close" @click="closeAbout"></div>
        </div>
      </transition>
      <div class="new__close close__mobile" @click="closeNew"></div>
      <transition name="zoom">
        <div class="new" v-if="newStart" v-bind:class="{unzoom: questionsUnzoom}">
          <transition name="title">
            <div id="new_btn" class="new__title title gtm-new" @click="openNew" v-if="newTitleStart">спазмалгон<sup>&reg;</sup> эффект</div>
          </transition>
          <div class="new__content" v-if="newpage">
            <div v-bar class="vuebar-element">
              <div>
                <div class="new__products">
                  <div class="new__product new__product--1">
                    <div class="new__product-title">{{ new_slide.acf.title_1 }}</div>
                    <div class="new__product-image"><img loading="lazy" :src="new_slide.acf.image_1.url + '?dii'"
                                                         alt=""></div>
                  </div>
                  <div class="new__product new__product--2">
                    <div class="new__product-title" v-html="new_slide.acf.title_2 "></div>
                    <div class="new__product-image"><img loading="lazy" :src="new_slide.acf.image_2.url + '?dii'"
                                                         alt=""></div>
                  </div>
                </div>
                <div class="new__text">
                  <div v-html="new_slide.acf.text"></div>
                  <span v-html="new_slide.acf.sup"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="new__close" @click="closeNew"></div>
        </div>
      </transition>
      <div class="questions__close close__mobile" @click="closeQuestions"></div>
      <transition name="zoom">
        <div class="questions" v-if="questionsStart" v-bind:class="{unzoom: questionsUnzoom}">
          <transition name="title">
            <div id="question_btn" class="questions__title title gtm-ques" @click="openQuestions"
                 v-if="questionsTitleStart">вопросы
            </div>
          </transition>
          <div class="questions__close" @click="closeQuestions"></div>
          <div class="questions__content" v-if="questions">
            <div class="swiper-container swiper-container-2">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="q in questions_slide" v-bind:key="q.id">
                  <div v-bar class="vuebar-element">
                    <div>
                      <div class="questions__item">{{ q.acf.order }}. {{ q.acf.title }}</div>
                      <div class="questions__text">
                        <div v-html="q.acf.text"></div>
                        <span v-html="q.acf.sup"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination-2"></div>
          </div>
        </div>
      </transition>
      <div class="information__close close__mobile" @click="closeInfo"></div>
      <transition name="zoom">
        <div class="information" v-if="informationStart" @mouseenter="infoHover" @mouseleave="infoUnHover">
          <transition name="title">
            <div id="info_btn" class="information__title title grm-info"
                 @click="openInfo(0, 'instruktsiya-spasmalgon-effect')" v-if="informationTitleStart">инструкции
            </div>
          </transition>
          <div class="information__close" @click="closeInfo"></div>
          <div class="information__content" v-if="info">
            <div class="swiper-container swiper-container-3">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="info in info_slide" v-bind:key="info.id">
                  <div v-bar class="vuebar-element">
                    <div>
                      <div class="information__text">
                        <div v-html="info.acf.text"></div>
                        <a :href="info.acf.file" target="_blank" class="information__button instr">
                          <i></i>
                          <p v-html="info.acf.sup"></p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-pagination swiper-pagination-3"></div>
          </div>
        </div>
      </transition>
    </div>
    <div class="footer">
      <div class="footer__link">
        <img src="./assets/img/teva_slogan.svg" alt="открывая возможности для доступного здоровья">
      </div>
      <div class="footer__title">Имеются противопоказания. Перед применением необходимо проконсультироваться со
        специалистом
      </div>
      <div class="footer__text">Регистрационный номер: П N013079/01; ЛП-003690 © ООО «Тева» Россия, 115054, г. Москва,
        ул. Валовая, д. 35, тел. (495) 644-22-34 , факс (495) 644-22-35. E-mail: info@teva.ru , www.teva.ru.
        SPAS-RU-00289-CONS-06.2024<br>
        <a href="https://www.teva.ru/privacy-and-cookie-policy/" target="_blank" class="footer__text-link"
           style="margin-right: 10px;">
          Политика о конфиденциальности и обработке файлов cookie
        </a><br class="hide-on-tab-and-desktop">
        <a href="https://www.teva.ru/user-agreement/" class="footer__text-link" target="_blank">
          Пользовательское соглашение
        </a>
      </div>
      <a href="https://www.teva.ru/" target="_blank" aria-label="Перейти на сайт Teva">
        <div class="footer__logo"></div>
      </a>
    </div>

    <div class="popup-cart-teva__container">
      <div class="cart-teva">
        <p class="cart-teva__heading">Почему<br>Teva?</p>
        <div class="cart-teva__button">
          <img src="./assets/img/cart-teva.svg" alt="teva.ru">
        </div>
        <img class="cart-teva__image" src="./assets/img/cart-gradient.svg" alt="cart">
      </div>

      <main class="container-banner with-link">
        <h2>Почему Teva?</h2>
        <span class="container-banner__close">
          <img src="./assets/img/cross-popup.svg" alt="">
        </span>
        <div class="container-banner__content">
          <h1 class="container-banner__big-txt">Teva – это высокое<br>качество препаратов<br>по доступной цене</h1>
          <p class="container-banner__md-txt level">Хорошее соотношение «цена-качество» подтверждает большинство
            опрошенных потребителей<sup>1</sup></p>
          <div class="container-banner__info">
            <p class="container-banner__sml-txt">1. 64% потребителей. Исследование Ipsos «Брендированные дженерики Teva.
              <br>Трекер здоровья бренда», потребители, 2021 г.
            </p>
            <p class="container-banner__banner-number">NPS-RU-00657-INT-07.2023</p>
            <a href="https://www.teva.ru/our-medicines/teva-quality/" class="container-banner__more-info">Узнать
              больше</a>
          </div>
        </div>
        <span class="container-banner__fon">
          <img src="./assets/img/fon-popup.jpg" alt="popup-fon">
        </span>
      </main>
    </div>

    <div class="popup-uteka" id="popup-uteka">
      <div class="uteka-widget">
        <div class="uteka-widget__header">
          <div class="uteka-widget__title"></div>

          <a class="uteka-widget__logo" href="https://uteka.ru/" target="_blank">
            <img src="https://widget.uteka.ru/static/img/widgets/logo-light.svg" alt="Заказать в Ютеке"
                 title="поиск в аптеках"/>
          </a>
          <a href="#" class="uteka-widget__close" id="popup-uteka__close" @click="close_modal_pharms()"></a>
        </div>
        <iframe allow="geolocation"></iframe>

        <div class="pharma-container">
          <div class="pharma-title">
            Забронировать в ближайшей аптеке или заказать с доставкой на дом
          </div>
          <div class="pharma-wrap">
            <div class="pharms__item">
              <a href="https://apteka.ru/preparation/spazmalgon/?utm_source=teva&utm_medium=buy_now&utm_campaign=spazmalgon"
                 target="_blank">
                <img loading="lazy" src="./assets/img/image_4.png" alt="Apteka.ru">
              </a>
            </div>
            <div class="pharms__item">
              <a href="https://zdravcity.ru/g_spazmalgon/?utm_source=teva&utm_medium=buy_now&utm_campaign=spazmalgon"
                 target="_blank">
                <img loading="lazy" src="./assets/img/image_8.png" alt="Zdravcity.ru">
              </a>
            </div>
            <div class="pharms__item">
              <a href="https://www.eapteka.ru/goods/brand/spazmalgon/" target="_blank">
                <img loading="lazy" src="./assets/img/image_14.png" alt="EApteka">
              </a>
            </div>
            <div class="pharms__item">
              <a href="https://planetazdorovo.ru/brand/spazmalgon-25/?utm_source=teva_spazmalgon&utm_medium=button"
                 target="_blank">
                <img loading="lazy" src="./assets/img/image_11.png" alt="Планета здоровья">
              </a>
            </div>
            <div class="pharms__item">
              <a href="https://www.rigla.ru/forms/spazmalgon" target="_blank">
                <img loading="lazy" src="./assets/img/image_12.png" alt="Риглa">
              </a>
            </div>
            <div class="pharms__item">
              <a href="https://366.ru/g/spazmalgon/" target="_blank">
                <img loading="lazy" src="./assets/img/image_16.png" alt="36.6">
              </a>
            </div>
            <div class="pharms__item">
              <a href="https://stoletov.ru/alphabet/s/spazmalgon/" target="_blank">
                <img loading="lazy" src="./assets/img/image_10.png" alt="Столетов">
              </a>
            </div>
            <div class="pharms__item">
              <a href="https://gorzdrav.org/catalog/spazmalgon/" target="_blank">
                <img loading="lazy" src="./assets/img/image_13.png" alt="Горздрав">
              </a>
            </div>
            <div class="pharms__item">
              <a href="https://ozerki.ru/alphabet/s/spazmalgon/" target="_blank">
                <img loading="lazy" src="./assets/img/image_9.png" alt="Озерки">
              </a>
            </div>
            <div class="pharms__item">
              <a href="https://superapteka.ru/alphabet/s/spazmalgon/" target="_blank">
                <img loading="lazy" src="./assets/img/image_15.png" alt="Superapteka">
              </a>
            </div>
            <div class="pharma-title promo">
              ALMA-RU-00217-CONS-07.2022
            </div>
          </div>
        </div>

        <div class="footer">
          <div class="footer__link">
            <img src="./assets/img/teva_slogan.svg" alt="открывая возможности для доступного здоровья">
          </div>
          <div class="footer__title">Имеются противопоказания. Перед применением необходимо проконсультироваться со
            специалистом
          </div>
          <div class="footer__text">Регистрационный номер: П N013079/01; ЛП-003690 © ООО «Тева» Россия, 115054, г.
            Москва, ул. Валовая, д. 35, тел. (495) 644-22-34 , факс (495) 644-22-35. E-mail: info@teva.ru , www.teva.ru.
            SPAS-RU-00289-CONS-06.2024<br>
              <a href="https://www.teva.ru/privacy-and-cookie-policy/" target="_blank" style="margin-right: 10px;">Политика о
              конфиденциальности и
              обработке файлов cookie</a>
              <a href="https://www.teva.ru/user-agreement/" target="_blank">Пользовательское соглашение</a>
              <a href="https://www.teva.ru/privacy-and-cookie-policy/" target="_blank" style="margin-right: 10px;">Политика о
              конфиденциальности и
              обработке файлов cookie</a>
              <a href="https://www.teva.ru/user-agreement/" target="_blank">Пользовательское соглашение</a>
          </div>
          <a href="https://www.teva.ru/" target="_blank" aria-label="Перейти на сайт Teva">
            <div class="footer__logo"></div>
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Vue from 'vue';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import Vuebar from 'vuebar';
import axios from 'axios';
import VueAxios from 'vue-axios';
import jQuery from 'jquery';
import { response_1, response_2, response_3, response_4, response_5, response_6, response_7, response_8 } from './data'
const $ = jQuery;
window.$ = $;
import './assets/styles/styles.sass';
import './assets/styles/vue-bar.scss';
import './assets/styles/cart.sass';
import './assets/styles/uteka.sass';
import './assets/js/tevaPopup.js';

Vue.use(Vuebar);
Vue.use(VueAxios, axios);


export default {
  name: 'app',
  data: function () {
    return {
      about_slide_1: response_1,
      about_slide_2: response_2,
      about_slide_3: response_3,
      new_slide: response_5,
      questions_slide: response_6,
      info_slide: response_7,
      main_image: response_4,
      social: false,
      questions: false,
      info: false,
      about: false,
      newpage: false,
      aboutSlider: null,
      questionsSlider: null,
      infoSlider: null,
      mainStart: false,
      aboutStart: false,
      newStart: false,
      questionsStart: false,
      informationStart: false,
      mainTitleStart: false,
      aboutTitleStart: false,
      newTitleStart: false,
      questionsTitleStart: false,
      informationTitleStart: false,
      purl: false,
      ptitle: false,
      pimg: false,
      text: false,
      share: false,
      questionsUnzoom: false,
    }
  },

  created() {
    this.addPixelWeborama('#app', 47);
  },
  methods: {
    open_modal_pharms() {
      let popupContainer = document.getElementById("popup-uteka");
      popupContainer.style.display = "block";
      popupContainer.classList.add("-is--active");
      popupContainer.classList.remove("-is--hidden");
      document.body.style.overflow = "hidden";

      let productIds = ["30956", "30958", "336255", "30957", "336256"]
      let widget = document.querySelector('.uteka-widget');


      let iframe = widget.querySelector('iframe')
      let query = []

      if (productIds) {
        productIds.forEach(function (productId) {
          query.push('productIds=' + productId)
        })
      }

      iframe.src = 'https://widget.uteka.ru/widgets/full/?' + query.join('&')

    },
    close_modal_pharms() {
      let popupContainer = document.getElementById("popup-uteka");
      popupContainer.style.display = "none";
      popupContainer.classList.remove("-is--active");
      popupContainer.classList.add("-is--hidden");
      popupContainer.querySelector('iframe').src = "";
      document.body.style.overflow = "initial";
    },

    share_it(share, purl, ptitle, pimg, text) {
      if (share == 'vk') {
        let url = 'http://vk.com/share.php?';
        if (purl) {
          url += 'url=' + encodeURIComponent(purl);
        }
        if (ptitle) {
          url += '&title=' + encodeURIComponent(ptitle);
        }
        if (pimg) {
          url += '&image=' + encodeURIComponent(pimg);
        }
        url += '&noparse=true';
        this.popup(url);
      }
      if (share == 'fb') {
        let url = 'http://www.fb.com/sharer.php?s=100';
        if (ptitle) {
          url += '&p[title]=' + encodeURIComponent(ptitle);
        }
        if (text) {
          url += '&p[summary]=' + encodeURIComponent(text);
        }
        if (purl) {
          url += '&p[url]=' + encodeURIComponent(purl);
        }
        if (pimg) {
          url += '&p[images][0]=' + encodeURIComponent(pimg);
        }
        this.popup(url);
      }
      if (share == 'ok') {
        let url = 'https://connect.ok.ru/offer';
        if (purl) {
          url += '?url=' + encodeURIComponent(purl);
        }
        if (text) {
          url += '&description=' + encodeURIComponent(text);
        }
        if (ptitle) {
          url += '&title=' + encodeURIComponent(ptitle);
        }
        if (pimg) {
          url += '&imageUrl=' + encodeURIComponent(pimg);
        }
        this.popup(url);
      }
    },
    popup(url) {
      window.open(url, '', 'toolbar=0,status=0,width=626,height=436');
    },
    addPixelWeborama(id, pixel) {
      document.querySelector(id).insertAdjacentHTML("beforeend", "<img style='position:absolute;' src='https://teva.solution.weborama.fr/fcgi-bin/dispatch.fcgi?a.A=co&a.si=7230&a.cp=" + pixel + "&a.ct=d' width='1' height='1'>")
    },
    openAbout() {
      this.addPixelWeborama('#about_btn', 48);
      window.history.pushState({'block': 'about'}, 'About', '#o-preparate');
      if (!this.about) {
        this.about = true;
        this.newpage = false;
        this.questions = false;
        this.info = false;
        this.$nextTick(function () {
          this.initAboutSlider();
          window.addEventListener("resize", this.updateSlider);
        });
      }
    },
    initAboutSlider() {
      this.aboutSlider = new Swiper('.swiper-container-1', {
        speed: 700,
        loop: false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        virtualTranslate: false,
        mousewheel: {
          invert: false,
          releaseOnEdges: false,
          forceToAxis: false,
        },
        pagination: {
          el: '.swiper-pagination-1',
          type: 'bullets',
          clickable: true,
        },
        breakpoints: {
          320: {
            direction: 'horizontal',
          },
          1000: {
            direction: 'vertical',
          }
        }
      });
    },
    updateSlider() {
      this.aboutSlider.destroy();
      this.initAboutSlider();
    },
    updateSliderInfo() {
      this.infoSlider.destroy();
      this.infoSlider = new Swiper('.swiper-container-3', {
        speed: 700,
        loop: false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        mousewheel: {
          invert: false,
          releaseOnEdges: true,
          forceToAxis: false,
          sensitivity: 0,
        },
        pagination: {
          el: '.swiper-pagination-3',
          type: 'bullets',
          clickable: true,
        },
        breakpoints: {
          320: {
            direction: 'horizontal',
          },
          1000: {
            direction: 'vertical',
          }
        }
      });
    },
    updateSliderQ() {
      this.questionsSlider.destroy();
      this.questionsSlider = new Swiper('.swiper-container-2', {
        speed: 700,
        loop: false,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        mousewheel: {
          invert: false,
          releaseOnEdges: true,
          forceToAxis: false,
          sensitivity: 0,
        },
        pagination: {
          el: '.swiper-pagination-2',
          type: 'bullets',
          clickable: true,
        },
        breakpoints: {
          320: {
            direction: 'horizontal',
          },
          1000: {
            direction: 'vertical',
          }
        }
      });
    },
    infoHover() {
      if (!this.info) {
        this.questionsUnzoom = true;
      }
    },
    infoUnHover() {
      this.questionsUnzoom = false;
    },
    closeAbout() {
      this.about = false;
      window.history.pushState({}, '', '');
    },
    closeInfo() {
      this.info = false;
      window.history.pushState({}, '', '');
    },
    openNew() {
      this.addPixelWeborama('#new_btn', 49);
      window.history.pushState({'block': 'bol-i-spazm'}, 'bol-i-spazm', '#bol-i-spazm');
      this.about = false;
      this.newpage = true;
      this.questions = false;
      this.info = false;
    },
    openInfo(slide, state) {
      this.addPixelWeborama('#info_btn', 51);
      window.history.pushState({'block': state}, state, '#' + state);
      if (!this.info) {
        this.about = false;
        this.newpage = false;
        this.questions = false;
        this.info = true;
        this.$nextTick(function () {
          this.infoSlider = new Swiper('.swiper-container-3', {
            initialSlide: slide,
            speed: 700,
            loop: false,
            effect: 'fade',
            fadeEffect: {
              crossFade: true
            },
            mousewheel: {
              invert: false,
              releaseOnEdges: true,
              forceToAxis: false,
              sensitivity: 0,
            },
            pagination: {
              el: '.swiper-pagination-3',
              type: 'bullets',
              clickable: true,
            },
            breakpoints: {
              320: {
                direction: 'horizontal',
              },
              1000: {
                direction: 'vertical',
              }
            }
          });
          window.addEventListener("resize", this.updateSliderInfo);
        });
      }
    },
    openQuestions() {
      this.addPixelWeborama('#question_btn', 50);
      window.history.pushState({'block': 'chastye-voprosy'}, 'chastye-voprosy', '#chastye-voprosy');
      if (!this.questions) {
        this.about = false;
        this.newpage = false;
        this.questions = true;
        this.info = false;
        this.$nextTick(function () {
          this.questionsSlider = new Swiper('.swiper-container-2', {
            speed: 700,
            loop: false,
            effect: 'fade',
            fadeEffect: {
              crossFade: true
            },
            mousewheel: {
              invert: false,
              releaseOnEdges: true,
              forceToAxis: false,
              sensitivity: 0,
            },
            pagination: {
              el: '.swiper-pagination-2',
              type: 'bullets',
              clickable: true,
            },
            breakpoints: {
              320: {
                direction: 'horizontal',
              },
              1000: {
                direction: 'vertical',
              }
            }
          });
          window.addEventListener("resize", this.updateSliderQ);
        });
      }
    },
    closeQuestions() {
      this.questions = false;
      window.history.pushState({}, '', '');
    },
    closeNew() {
      this.newpage = false;
      window.history.pushState({}, '', '');
    },
  },
  mounted() {
    this.pharms = response_8;

    this.mainStart = true;
    setTimeout(() => {
      this.mainTitleStart = true;
    }, 150);
    setTimeout(() => {
      this.aboutStart = true;
    }, 100);
    setTimeout(() => {
      this.aboutTitleStart = true;
    }, 250);
    setTimeout(() => {
      this.newStart = true;
    }, 200);
    setTimeout(() => {
      this.newTitleStart = true;
    }, 350);
    setTimeout(() => {
      this.questionsStart = true;
    }, 300);
    setTimeout(() => {
      this.questionsTitleStart = true;
    }, 450);
    setTimeout(() => {
      this.informationStart = true;
    }, 400);
    setTimeout(() => {
      this.informationTitleStart = true;
    }, 550);
    setTimeout(() => {
      switch (window.location.hash) {
        case '#o-preparate':
          this.openAbout();
          break;
        case '#bol-i-spazm':
          this.openNew();
          break;
        case '#chastye-voprosy':
          this.openQuestions();
          break;
        case '#instruktsiya-spasmalgon-effect':
          this.openInfo(0, 'instruktsiya-spasmalgon-effect');
          break;
        case '#instruktsiya-spasmalgon':
          this.openInfo(1, 'instruktsiya-spasmalgon');
          break;
        default:
          break;
      }
    }, 650)
  }
}
</script>

<style>
article, aside, details, figcaption, figure, footer, header, hgroup, hr, menu, nav, section {
  display: block
}

a, hr {
  padding: 0
}

abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0
}

ins, mark {
  background-color: #ff9;
  color: #000
}

body {
  line-height: 1
}

nav ul {
  list-style: none
}

blockquote, q {
  quotes: none
}

blockquote:after, blockquote:before, q:after, q:before {
  content: '';
  content: none
}

a {
  margin: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0
}

ins {
  text-decoration: none
}

mark {
  font-style: italic;
  font-weight: 700
}

del {
  text-decoration: line-through
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0
}

input, select {
  vertical-align: middle
}


@font-face {
  font-family: 'Bebas Neue Book';
  src: url('assets/font/subset-BebasNeueBook.eot');
  src: url('assets/font/subset-BebasNeueBook.eot?#iefix') format('embedded-opentype'),
  url('assets/font/subset-BebasNeueBook.woff2') format('woff2'),
  url('assets/font/subset-BebasNeueBook.woff') format('woff'),
  url('assets/font/subset-BebasNeueBook.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr Regular';
  src: url('assets/font/AvenirNextCyr-Regular.eot');
  src: url('assets/font/AvenirNextCyr-Regular.eot?#iefix') format('embedded-opentype'),
  url('assets/font/AvenirNextCyr-Regular.woff') format('woff'),
  url('assets/font/AvenirNextCyr-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
</style>
