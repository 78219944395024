export const response_1 = {
  id: 15,
  date: '2020-01-20T20:48:36',
  date_gmt: '2020-01-20T17:48:36',
  guid: {
    rendered: 'https://spasmalgon.ru/?post_type=about&#038;p=15',
  },
  modified: '2024-04-26T13:50:29',
  modified_gmt: '2024-04-26T10:50:29',
  slug: '1-%d1%81%d0%bb%d0%b0%d0%b9%d0%b4',
  status: 'publish',
  type: 'about',
  link: 'https://spasmalgon.teva.ru/about/1-%d1%81%d0%bb%d0%b0%d0%b9%d0%b4/',
  title: {
    rendered: '1 слайд',
  },
  content: {
    rendered: '',
    protected: false,
  },
  featured_media: 0,
  template: '',
  acf: {
    text: '<p><strong>Спазмалгон®</strong> выпускается в таблетках:</p>',
    title_1: '10 таблеток',
    image_1:
      'https://spasmalgon.teva.ru/wp-content/uploads/2020/01/main-img10.png',
    title_2: '20 таблеток',
    image_2:
      'https://spasmalgon.teva.ru/wp-content/uploads/2020/01/main-img20.png',
    title_3: '50 таблеток',
    image_3:
      'https://spasmalgon.teva.ru/wp-content/uploads/2020/01/main-img50.png',
    sup: '1. Наиболее выгодная стоимость среди всей линейки препаратов Спазмалгон® <br>при пересчёте на стоимость 1 таблетки. «IQVIA», средняя розничная стоимость<br> за период  январь-декабрь 2023 года.',
    label: 'Выгодная<br>цена <sup>[1]</sup>',
  },
  _links: {
    self: [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/about/15',
      },
    ],
    collection: [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/about',
      },
    ],
    about: [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/about',
      },
    ],
    'wp:attachment': [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=15',
      },
    ],
    curies: [
      {
        name: 'wp',
        href: 'https://api.w.org/{rel}',
        templated: true,
      },
    ],
  },
};
