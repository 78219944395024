export const response_8 = [
  {
    id: 144,
    date: '2020-11-10T15:31:46',
    date_gmt: '2020-11-10T12:31:46',
    guid: {
      rendered: 'https://spasmalgon.ru/?post_type=pharmacies&#038;p=144',
    },
    modified: '2022-08-02T15:16:15',
    modified_gmt: '2022-08-02T12:16:15',
    slug: '%d0%b0%d0%bf%d1%82%d0%b5%d0%ba%d0%b0-%d1%80%d1%83',
    status: 'publish',
    type: 'pharmacies',
    link: 'https://spasmalgon.teva.ru/pharmacies/%d0%b0%d0%bf%d1%82%d0%b5%d0%ba%d0%b0-%d1%80%d1%83/',
    title: {
      rendered: 'Аптека.ру',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Аптека.ру',
      url: 'https://apteka.ru/preparation/spazmalgon/?utm_source=teva&utm_medium=buy_now&utm_campaign=spazmalgon',
      logo: 'https://spasmalgon.teva.ru/wp-content/uploads/2020/11/apteka-1.jpg',
    },
    _links: {
      self: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies/144',
        },
      ],
      collection: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies',
        },
      ],
      about: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/pharmacies',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=144',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 147,
    date: '2020-11-10T15:31:48',
    date_gmt: '2020-11-10T12:31:48',
    guid: {
      rendered: 'https://spasmalgon.ru/?post_type=pharmacies&#038;p=147',
    },
    modified: '2020-11-17T18:05:53',
    modified_gmt: '2020-11-17T15:05:53',
    slug: '%d1%81%d1%82%d0%be%d0%bb%d0%b8%d1%87%d0%ba%d0%b8',
    status: 'publish',
    type: 'pharmacies',
    link: 'https://spasmalgon.teva.ru/pharmacies/%d1%81%d1%82%d0%be%d0%bb%d0%b8%d1%87%d0%ba%d0%b8/',
    title: {
      rendered: 'Столички',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Столички',
      url: 'https://stolichki.ru/search?name=%D0%A1%D0%BF%D0%B0%D0%B7%D0%BC%D0%B0%D0%BB%D0%B3%D0%BE%D0%BD',
      logo: 'https://spasmalgon.teva.ru/wp-content/uploads/2020/11/apteka-2.jpg',
    },
    _links: {
      self: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies/147',
        },
      ],
      collection: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies',
        },
      ],
      about: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/pharmacies',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=147',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 158,
    date: '2020-11-18T13:27:16',
    date_gmt: '2020-11-18T10:27:16',
    guid: {
      rendered: 'https://spasmalgon.ru/?post_type=pharmacies&#038;p=158',
    },
    modified: '2022-05-24T17:27:30',
    modified_gmt: '2022-05-24T14:27:30',
    slug: '%d0%bf%d0%bb%d0%b0%d0%bd%d0%b5%d1%82%d0%b0-%d0%b7%d0%b4%d0%be%d1%80%d0%be%d0%b2%d1%8c%d1%8f',
    status: 'publish',
    type: 'pharmacies',
    link: 'https://spasmalgon.teva.ru/pharmacies/%d0%bf%d0%bb%d0%b0%d0%bd%d0%b5%d1%82%d0%b0-%d0%b7%d0%b4%d0%be%d1%80%d0%be%d0%b2%d1%8c%d1%8f/',
    title: {
      rendered: 'Планета здоровья',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Планета здоровья',
      url: 'https://planetazdorovo.ru/brand/spazmalgon-25/',
      logo: 'https://spasmalgon.teva.ru/wp-content/uploads/2020/11/apteka-5.jpg',
    },
    _links: {
      self: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies/158',
        },
      ],
      collection: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies',
        },
      ],
      about: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/pharmacies',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=158',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 160,
    date: '2020-11-18T13:28:51',
    date_gmt: '2020-11-18T10:28:51',
    guid: {
      rendered: 'https://spasmalgon.ru/?post_type=pharmacies&#038;p=160',
    },
    modified: '2020-11-18T13:28:51',
    modified_gmt: '2020-11-18T10:28:51',
    slug: '%d0%be%d0%b7%d0%b5%d1%80%d0%ba%d0%b8',
    status: 'publish',
    type: 'pharmacies',
    link: 'https://spasmalgon.teva.ru/pharmacies/%d0%be%d0%b7%d0%b5%d1%80%d0%ba%d0%b8/',
    title: {
      rendered: 'Озерки',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Озерки',
      url: 'https://6030000.ru/catalog/?q=%D1%81%D0%BF%D0%B0%D0%B7%D0%BC%D0%B0%D0%BB%D0%B3%D0%BE%D0%BD',
      logo: 'https://spasmalgon.teva.ru/wp-content/uploads/2020/11/apteka-3.jpg',
    },
    _links: {
      self: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies/160',
        },
      ],
      collection: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies',
        },
      ],
      about: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/pharmacies',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=160',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 162,
    date: '2020-11-18T13:29:37',
    date_gmt: '2020-11-18T10:29:37',
    guid: {
      rendered: 'https://spasmalgon.ru/?post_type=pharmacies&#038;p=162',
    },
    modified: '2020-11-18T13:29:38',
    modified_gmt: '2020-11-18T10:29:38',
    slug: '%d1%80%d0%b8%d0%b3%d0%bb%d0%b0',
    status: 'publish',
    type: 'pharmacies',
    link: 'https://spasmalgon.teva.ru/pharmacies/%d1%80%d0%b8%d0%b3%d0%bb%d0%b0/',
    title: {
      rendered: 'Ригла',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Ригла',
      url: 'https://www.rigla.ru/search?q=%25D1%2581%25D0%25BF%25D0%25B0%25D0%25B7%25D0%25BC%25D0%25B0%25D0%25BB%25D0%25B3%25D0%25BE%25D0%25BD',
      logo: 'https://spasmalgon.teva.ru/wp-content/uploads/2020/11/apteka-4.jpg',
    },
    _links: {
      self: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies/162',
        },
      ],
      collection: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies',
        },
      ],
      about: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/pharmacies',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=162',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 164,
    date: '2020-11-18T13:30:30',
    date_gmt: '2020-11-18T10:30:30',
    guid: {
      rendered: 'https://spasmalgon.ru/?post_type=pharmacies&#038;p=164',
    },
    modified: '2022-08-02T14:46:14',
    modified_gmt: '2022-08-02T11:46:14',
    slug: '%d0%b7%d0%b4%d1%80%d0%b0%d0%b2%d1%81%d0%b8%d1%82%d0%b8',
    status: 'publish',
    type: 'pharmacies',
    link: 'https://spasmalgon.teva.ru/pharmacies/%d0%b7%d0%b4%d1%80%d0%b0%d0%b2%d1%81%d0%b8%d1%82%d0%b8/',
    title: {
      rendered: 'Здравсити',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Здравсити',
      url: 'https://zdravcity.ru/g_spazmalgon/?utm_source=teva&utm_medium=buy_now&utm_campaign=spazmalgon',
      logo: 'https://spasmalgon.teva.ru/wp-content/uploads/2020/11/apteka-6.jpg',
    },
    _links: {
      self: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies/164',
        },
      ],
      collection: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies',
        },
      ],
      about: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/pharmacies',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=164',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 166,
    date: '2020-11-18T13:31:53',
    date_gmt: '2020-11-18T10:31:53',
    guid: {
      rendered: 'https://spasmalgon.ru/?post_type=pharmacies&#038;p=166',
    },
    modified: '2020-11-18T13:31:54',
    modified_gmt: '2020-11-18T10:31:54',
    slug: '%d0%b5%d0%b0%d0%bf%d1%82%d0%b5%d0%ba%d0%b0',
    status: 'publish',
    type: 'pharmacies',
    link: 'https://spasmalgon.teva.ru/pharmacies/%d0%b5%d0%b0%d0%bf%d1%82%d0%b5%d0%ba%d0%b0/',
    title: {
      rendered: 'еАптека',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'еАптека',
      url: 'https://www.eapteka.ru/search/?q=%D1%81%D0%BF%D0%B0%D0%B7%D0%BC%D0%B0%D0%BB%D0%B3%D0%BE%D0%BD',
      logo: 'https://spasmalgon.teva.ru/wp-content/uploads/2020/11/apteka-7.jpg',
    },
    _links: {
      self: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies/166',
        },
      ],
      collection: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies',
        },
      ],
      about: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/pharmacies',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=166',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 169,
    date: '2020-11-19T13:18:47',
    date_gmt: '2020-11-19T10:18:47',
    guid: {
      rendered: 'https://spasmalgon.ru/?post_type=pharmacies&#038;p=169',
    },
    modified: '2020-11-19T13:18:47',
    modified_gmt: '2020-11-19T10:18:47',
    slug: '%d0%b3%d0%be%d1%80%d0%b7%d0%b4%d1%80%d0%b0%d0%b2',
    status: 'publish',
    type: 'pharmacies',
    link: 'https://spasmalgon.teva.ru/pharmacies/%d0%b3%d0%be%d1%80%d0%b7%d0%b4%d1%80%d0%b0%d0%b2/',
    title: {
      rendered: 'Горздрав',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Горздрав',
      url: 'https://gorzdrav.org/catalog/?q=%D1%81%D0%BF%D0%B0%D0%B7%D0%BC%D0%B0%D0%BB%D0%B3%D0%BE%D0%BD',
      logo: 'https://spasmalgon.teva.ru/wp-content/uploads/2020/11/apteka-8.jpg',
    },
    _links: {
      self: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies/169',
        },
      ],
      collection: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies',
        },
      ],
      about: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/pharmacies',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=169',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 171,
    date: '2020-11-19T13:21:07',
    date_gmt: '2020-11-19T10:21:07',
    guid: {
      rendered: 'https://spasmalgon.ru/?post_type=pharmacies&#038;p=171',
    },
    modified: '2022-05-24T17:29:52',
    modified_gmt: '2022-05-24T14:29:52',
    slug: '%d0%b0%d0%bf%d1%82%d0%b5%d0%ba%d0%b0-%d0%b8%d0%bd%d0%b4%d0%b5%d0%bf',
    status: 'publish',
    type: 'pharmacies',
    link: 'https://spasmalgon.teva.ru/pharmacies/%d0%b0%d0%bf%d1%82%d0%b5%d0%ba%d0%b0-%d0%b8%d0%bd%d0%b4%d0%b5%d0%bf/',
    title: {
      rendered: 'Аптека ИНДЕП',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Аптека ИНДЕП',
      url: 'https://www.indep.ru/catalog/?q=%D1%81%D0%BF%D0%B0%D0%B7%D0%BC%D0%B0%D0%BB%D0%B3%D0%BE%D0%BD',
      logo: 'https://spasmalgon.teva.ru/wp-content/uploads/2020/11/apteka-9.jpg',
    },
    _links: {
      self: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies/171',
        },
      ],
      collection: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pharmacies',
        },
      ],
      about: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/pharmacies',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=171',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
];
