export const response_5 = {
  id: 46,
  date: '2020-01-20T19:53:34',
  date_gmt: '2020-01-20T16:53:34',
  guid: {
    rendered: 'https://spasmalgon.ru/?page_id=46',
  },
  modified: '2024-04-26T14:40:06',
  modified_gmt: '2024-04-26T11:40:06',
  slug: '%d0%bd%d0%be%d0%b2%d0%b8%d0%bd%d0%ba%d0%b8',
  status: 'publish',
  type: 'page',
  link: 'https://spasmalgon.teva.ru/%d0%bd%d0%be%d0%b2%d0%b8%d0%bd%d0%ba%d0%b8/',
  title: {
    rendered: 'СПАЗМАЛГОН® ЭФФЕКТ',
  },
  content: {
    rendered: '',
    protected: false,
  },
  excerpt: {
    rendered: '',
    protected: false,
  },
  author: 1,
  featured_media: 0,
  parent: 0,
  menu_order: 0,
  comment_status: 'closed',
  ping_status: 'closed',
  template: '',
  meta: [],
  acf: {
    text: '<p>В состав нового СПАЗМАЛГОН® ЭФФЕКТ входят: анальгетик, нестероидное<br />противовоспалительное средство, спазмолитик, антигистаминное средство<br />и кофеин, способствующий усилению действия ненаркотических анальгетиков<sup>[1]</sup>. <br> Это комбинированный препарат, оказывающий болеутоляющее, противовоспалительное, спазмолитическое и жаропонижающее действие <sup>[1]</sup>.</p>',
    sup: '1 Листок-вкладыш – информация для пациента Спазмалгон® ЭФФЕКТ ЛП-№(001820)-(РГ-RU)',
    image:
      'https://spasmalgon.teva.ru/wp-content/uploads/2020/01/product_4-e1652714248739.png',
    title_1: '10 таблеток',
    image_1: {
      ID: 99,
      id: 99,
      title: 'image 18',
      filename: 'image-18-e1652714331367.png',
      filesize: 177550,
      url: 'https://spasmalgon.teva.ru/wp-content/uploads/2020/03/image-18-e1652714331367.png',
      link: 'https://spasmalgon.teva.ru/%d0%bd%d0%be%d0%b2%d0%b8%d0%bd%d0%ba%d0%b8/image-18/',
      alt: '',
      author: '1',
      description: '',
      caption: '',
      name: 'image-18',
      status: 'inherit',
      uploaded_to: 46,
      date: '2020-03-13 14:59:24',
      modified: '2020-03-13 14:59:24',
      menu_order: 0,
      mime_type: 'image/png',
      type: 'image',
      subtype: 'png',
      icon: 'https://spasmalgon.teva.ru/wp-includes/images/media/default.png',
      width: 600,
      height: 266,
      sizes: {
        thumbnail:
          'https://spasmalgon.teva.ru/wp-content/uploads/2020/03/image-18-150x150.png',
        'thumbnail-width': 150,
        'thumbnail-height': 150,
        medium:
          'https://spasmalgon.teva.ru/wp-content/uploads/2020/03/image-18-300x133.png',
        'medium-width': 300,
        'medium-height': 133,
        medium_large:
          'https://spasmalgon.teva.ru/wp-content/uploads/2020/03/image-18-e1652714331367.png',
        'medium_large-width': 600,
        'medium_large-height': 266,
        large:
          'https://spasmalgon.teva.ru/wp-content/uploads/2020/03/image-18-e1652714331367.png',
        'large-width': 600,
        'large-height': 266,
        '1536x1536':
          'https://spasmalgon.teva.ru/wp-content/uploads/2020/03/image-18-e1652714331367.png',
        '1536x1536-width': 600,
        '1536x1536-height': 266,
        '2048x2048':
          'https://spasmalgon.teva.ru/wp-content/uploads/2020/03/image-18-e1652714331367.png',
        '2048x2048-width': 600,
        '2048x2048-height': 266,
      },
    },
    title_2: '30 таблеток',
    image_2: {
      ID: 100,
      id: 100,
      title: 'image 19',
      filename: 'image-19-1-e1652714352609.png',
      filesize: 153813,
      url: 'https://spasmalgon.teva.ru/wp-content/uploads/2020/03/image-19-1-e1652714352609.png',
      link: 'https://spasmalgon.teva.ru/%d0%bd%d0%be%d0%b2%d0%b8%d0%bd%d0%ba%d0%b8/image-19-2/',
      alt: '',
      author: '1',
      description: '',
      caption: '',
      name: 'image-19-2',
      status: 'inherit',
      uploaded_to: 46,
      date: '2020-03-13 14:59:52',
      modified: '2022-05-16 15:19:17',
      menu_order: 0,
      mime_type: 'image/png',
      type: 'image',
      subtype: 'png',
      icon: 'https://spasmalgon.teva.ru/wp-includes/images/media/default.png',
      width: 600,
      height: 274,
      sizes: {
        thumbnail:
          'https://spasmalgon.teva.ru/wp-content/uploads/2020/03/image-19-1-150x150.png',
        'thumbnail-width': 150,
        'thumbnail-height': 150,
        medium:
          'https://spasmalgon.teva.ru/wp-content/uploads/2020/03/image-19-1-300x137.png',
        'medium-width': 300,
        'medium-height': 137,
        medium_large:
          'https://spasmalgon.teva.ru/wp-content/uploads/2020/03/image-19-1-e1652714352609.png',
        'medium_large-width': 600,
        'medium_large-height': 274,
        large:
          'https://spasmalgon.teva.ru/wp-content/uploads/2020/03/image-19-1-e1652714352609.png',
        'large-width': 600,
        'large-height': 274,
        '1536x1536':
          'https://spasmalgon.teva.ru/wp-content/uploads/2020/03/image-19-1-e1652714352609.png',
        '1536x1536-width': 600,
        '1536x1536-height': 274,
        '2048x2048':
          'https://spasmalgon.teva.ru/wp-content/uploads/2020/03/image-19-1-e1652714352609.png',
        '2048x2048-width': 600,
        '2048x2048-height': 274,
      },
    },
  },
  _links: {
    self: [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pages/46',
      },
    ],
    collection: [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pages',
      },
    ],
    about: [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/page',
      },
    ],
    author: [
      {
        embeddable: true,
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/users/1',
      },
    ],
    replies: [
      {
        embeddable: true,
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/comments?post=46',
      },
    ],
    'version-history': [
      {
        count: 14,
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pages/46/revisions',
      },
    ],
    'predecessor-version': [
      {
        id: 224,
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pages/46/revisions/224',
      },
    ],
    'wp:attachment': [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=46',
      },
    ],
    curies: [
      {
        name: 'wp',
        href: 'https://api.w.org/{rel}',
        templated: true,
      },
    ],
  },
};
