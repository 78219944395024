export const response_4 = {
  id: 7,
  date: '2020-01-20T18:21:46',
  date_gmt: '2020-01-20T15:21:46',
  guid: {
    rendered: 'https://spasmalgon.ru/?page_id=7',
  },
  modified: '2022-05-25T12:01:48',
  modified_gmt: '2022-05-25T09:01:48',
  slug: '%d0%b3%d0%bb%d0%b0%d0%b2%d0%bd%d0%b0%d1%8f',
  status: 'publish',
  type: 'page',
  link: 'https://spasmalgon.teva.ru/%d0%b3%d0%bb%d0%b0%d0%b2%d0%bd%d0%b0%d1%8f/',
  title: {
    rendered: 'Главная',
  },
  content: {
    rendered: '',
    protected: false,
  },
  excerpt: {
    rendered: '',
    protected: false,
  },
  author: 1,
  featured_media: 0,
  parent: 0,
  menu_order: 0,
  comment_status: 'closed',
  ping_status: 'closed',
  template: '',
  meta: [],
  acf: {
    image:
      'https://spasmalgon.teva.ru/wp-content/uploads/2022/05/0-e1652714248739.png',
    title: 'Спазмалгон - от боли и спазма.',
    btn_buy: true,
  },
  _links: {
    self: [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pages/7',
      },
    ],
    collection: [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pages',
      },
    ],
    about: [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/page',
      },
    ],
    author: [
      {
        embeddable: true,
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/users/1',
      },
    ],
    replies: [
      {
        embeddable: true,
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/comments?post=7',
      },
    ],
    'version-history': [
      {
        count: 30,
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pages/7/revisions',
      },
    ],
    'predecessor-version': [
      {
        id: 210,
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/pages/7/revisions/210',
      },
    ],
    'wp:attachment': [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=7',
      },
    ],
    curies: [
      {
        name: 'wp',
        href: 'https://api.w.org/{rel}',
        templated: true,
      },
    ],
  },
};
