document.addEventListener('DOMContentLoaded', () => {
    TevaPopupFunc();
});

const TevaPopupFunc = () => {
    const objAdaptive = {
        desktopBtn: 'cart-teva__button',
        tabletBtn: 'cart-teva',
        tabletSizeScreen: 768,
        mobileSizeScreen: 500,
    }

    const container = document.querySelector('.popup-cart-teva__container');
    if (!container) {
        return;
    }

    let width = window.innerWidth, button, clicked = false;
    
    if (width > objAdaptive.tabletSizeScreen) {
        button = container.querySelector(`.${objAdaptive.desktopBtn}`);
    } else {
        button = container.querySelector(`.${objAdaptive.tabletBtn}`);
    }
    const popup = container.querySelector('.container-banner');
    const close = popup.querySelector('.container-banner__close');

    button.addEventListener('click', () => {
        container.classList.add('is_open');
        clicked = true;
    });

    close.addEventListener('click', () => {
        container.classList.remove('is_open');
    });

    if(width > 500){
        const timeout = setTimeout(() => {
            container.classList.add('is_open');
            clicked = true;
        }, 60000); 

        document.addEventListener('click', () => {
            if(clicked){
                clearTimeout(timeout);
            } 
        });
    }

}