export const response_6 = [
  {
    id: 58,
    date: '2020-01-21T12:02:46',
    date_gmt: '2020-01-21T09:02:46',
    guid: {
      rendered: 'https://spasmalgon.ru/?post_type=questions&#038;p=58',
    },
    modified: '2024-04-26T14:47:36',
    modified_gmt: '2024-04-26T11:47:36',
    slug: '1-%d0%ba%d0%b0%d0%ba%d0%b8%d0%b5-%d0%ba%d0%be%d0%bc%d0%bf%d0%be%d0%bd%d0%b5%d0%bd%d1%82%d1%8b-%d0%b2%d1%85%d0%be%d0%b4%d1%8f%d1%82-%d0%b2-%d1%81%d0%be%d1%81%d1%82%d0%b0%d0%b2-%d0%bf%d1%80%d0%b5%d0%bf',
    status: 'publish',
    type: 'questions',
    link: 'https://spasmalgon.teva.ru/questions/1-%d0%ba%d0%b0%d0%ba%d0%b8%d0%b5-%d0%ba%d0%be%d0%bc%d0%bf%d0%be%d0%bd%d0%b5%d0%bd%d1%82%d1%8b-%d0%b2%d1%85%d0%be%d0%b4%d1%8f%d1%82-%d0%b2-%d1%81%d0%be%d1%81%d1%82%d0%b0%d0%b2-%d0%bf%d1%80%d0%b5%d0%bf/',
    title: {
      rendered: 'Какие компоненты входят в состав препарата Спазмалгон®?',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      title: 'Какие компоненты входят в состав препарата Спазмалгон®?',
      text: '<p>Препарат Спазмалгон® содержит в своём составе комбинацию обезболивающего и спазмолитического компонентов. Обезболивающий компонент представлен ненаркотическим анальгетиком метамизолом натрия, а спазмолитический компонент представлен двумя спазмолитиками разного механизма действия. <sup>[1]</sup></p>',
      sup: '1. Листок-вкладыш – информация для пациента Спазмалгон® ЛП-№(002098)-(РГ-RU)',
      order: '1',
    },
    _links: {
      self: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/questions/58',
        },
      ],
      collection: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/questions',
        },
      ],
      about: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/questions',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=58',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 60,
    date: '2020-01-21T12:05:21',
    date_gmt: '2020-01-21T09:05:21',
    guid: {
      rendered: 'https://spasmalgon.ru/?post_type=questions&#038;p=60',
    },
    modified: '2024-04-26T14:49:56',
    modified_gmt: '2024-04-26T11:49:56',
    slug: '3-%d0%be%d0%ba%d0%b0%d0%b7%d1%8b%d0%b2%d0%b0%d0%b5%d1%82-%d0%bb%d0%b8-%d1%81%d0%bf%d0%b0%d0%b7%d0%bc%d0%b0%d0%bb%d0%b3%d0%be%d0%bd-%d0%bd%d0%b5%d0%b3%d0%b0%d1%82%d0%b8%d0%b2%d0%bd%d0%be%d0%b5',
    status: 'publish',
    type: 'questions',
    link: 'https://spasmalgon.teva.ru/questions/3-%d0%be%d0%ba%d0%b0%d0%b7%d1%8b%d0%b2%d0%b0%d0%b5%d1%82-%d0%bb%d0%b8-%d1%81%d0%bf%d0%b0%d0%b7%d0%bc%d0%b0%d0%bb%d0%b3%d0%be%d0%bd-%d0%bd%d0%b5%d0%b3%d0%b0%d1%82%d0%b8%d0%b2%d0%bd%d0%be%d0%b5/',
    title: {
      rendered:
        'Оказывает ли Спазмалгон® негативное воздействие на желудочно-кишечный тракт?',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      title:
        'Оказывает ли Спазмалгон® негативное воздействие на желудочно-кишечный тракт?',
      text: '<p>Обезболивающий компонент препарата Спазмалгон® не относится к категории нестероидных противовоспалительных средств (НПВС). В отличие от препаратов, содержащих НПВС (препараты ибупрофена, напроксена, декскетопрофена, ацетилсалициловой кислоты), для препарата Спазмалгон® не характерны такие побочные действия, как эрозии и язвы желудочно-кишечного тракта. В отличие от содержащих НПВС препаратов, Спазмалгон® не противопоказан потребителям с эрозивно-язвенными поражениями желудочно-кишечного тракта, в том числе в фазе обострения.<sup>[1]</sup></p> <br >  <p>Не принимайте препарат, если у Вас желудочно-кишечная непроходимость и мегаколон (расширение толстой кишки), непереносимость лактозы, дефицит лактазы, глюкозо-галактозная мальабсорбция, выраженная печеночная недостаточность *2 (полный список противопоказаний см. в листке-вкладыше лекарственного препарата Спазмалгон® ЛП-3№(002098)-(РГ-RU))</p>',
      sup: '1. Листок-вкладыш – информация для пациента Спазмалгон® ЛП-№(002098)-(РГ-RU) <br > 2. Полный список противопоказаний см. в листке-вкладыше лекарственного препарата Спазмалгон® ЛП-3№(002098)-(РГ-RU))',
      order: '2',
    },
    _links: {
      self: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/questions/60',
        },
      ],
      collection: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/questions',
        },
      ],
      about: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/questions',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=60',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 62,
    date: '2020-01-21T12:06:25',
    date_gmt: '2020-01-21T09:06:25',
    guid: {
      rendered: 'https://spasmalgon.ru/?post_type=questions&#038;p=62',
    },
    modified: '2024-04-26T14:51:11',
    modified_gmt: '2024-04-26T11:51:11',
    slug: '5-%d0%ba%d0%b0%d0%ba-%d1%81%d0%bb%d0%b5%d0%b4%d1%83%d0%b5%d1%82-%d0%bf%d1%80%d0%b8%d0%bd%d0%b8%d0%bc%d0%b0%d1%82%d1%8c-%d0%bf%d1%80%d0%b5%d0%bf%d0%b0%d1%80%d0%b0%d1%82-%d1%81%d0%bf%d0%b0%d0%b7%d0%bc',
    status: 'publish',
    type: 'questions',
    link: 'https://spasmalgon.teva.ru/questions/5-%d0%ba%d0%b0%d0%ba-%d1%81%d0%bb%d0%b5%d0%b4%d1%83%d0%b5%d1%82-%d0%bf%d1%80%d0%b8%d0%bd%d0%b8%d0%bc%d0%b0%d1%82%d1%8c-%d0%bf%d1%80%d0%b5%d0%bf%d0%b0%d1%80%d0%b0%d1%82-%d1%81%d0%bf%d0%b0%d0%b7%d0%bc/',
    title: {
      rendered: 'Как следует принимать препарат Спазмалгон®?',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      title: 'Можно ли принимать Спазмалгон® при язвенной болезни в фазе обострения? Многие обезболивающие препараты при таком состоянии противопоказаны.',
      text: '<p>Действительно, большая часть обезболивающих препаратов содержит компоненты, относящиеся к нестероидным противовоспалительным средствам (НПВС). Многие НПВС-содержащие препараты противопоказаны при эрозивно-язвенных поражениях желудочно-кишечного тракта в фазе обострения. Метамизол препарата Спазмалгон® не относится к категории НПВС и имеет другой механизм действия.<sup>[1]</sup> Поэтому для препарата Спазмалгон® не характерны такие побочные действия, как эрозии и язвы желудочно-кишечного тракта. В отличие от содержащих НПВС препаратов, Спазмалгон® может применяться потребителями с эрозивно-язвенными поражениями желудочно-кишечного тракта, в том числе в фазе обострения.<sup>[2]</sup></p>',
      sup: '1. Jasiecka A et al. Pol j Vet Sci. 2014;17(1):207-14 <br>2. Листок-вкладыш – информация для пациента Спазмалгон® ЛП-№(002098)-(РГ-RU)',
      order: '3',
    },
    _links: {
      self: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/questions/62',
        },
      ],
      collection: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/questions',
        },
      ],
      about: [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/questions',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=62',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
];
