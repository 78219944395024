export const response_2 = {
  id: 31,
  date: '2020-01-20T20:23:43',
  date_gmt: '2020-01-20T17:23:43',
  guid: {
    rendered: 'https://spasmalgon.ru/?post_type=about&#038;p=31',
  },
  modified: '2024-05-02T13:45:07',
  modified_gmt: '2024-05-02T10:45:07',
  slug: '%d1%81%d0%bb%d0%b0%d0%b9%d0%b4-2',
  status: 'publish',
  type: 'about',
  link: 'https://spasmalgon.teva.ru/about/%d1%81%d0%bb%d0%b0%d0%b9%d0%b4-2/',
  title: {
    rendered: '2 слайд',
  },
  content: {
    rendered: '',
    protected: false,
  },
  featured_media: 0,
  template: '',
  acf: {
    text: '<p><strong>Спазмалгон®</strong> борется с болью и спазмом<sup>3</sup> благодаря обезболивающему компоненту и двум спазмолитикам разного механизма действия. При этом для препарата не характерны такие побочные действия, как эрозии и язвы желудочно-кишечного тракта <sup>[2]</sup>.</p>',
    title_1: 'обезболивающее',
    title_2: 'спазмолитическое<sup>[3]</sup>',
    title_3: 'жаропонижающее',
    title_4: 'благоприятный профиль <br>ЖКТ безопасности <sup>[4]</sup>',
    sup: '2. Листок-вкладыш – информация для пациента Спазмалгон® ЛП-№(002098)-(РГ-RU) <br>3. При альгодисменорее <br>4. Не противопоказан при эрозивно-язвенных поражениях ЖКТ, нехарактерны такие нежелательные реакции как эрозии и язвы ЖКТ (ЖКТ - желудочно-кишечный тракт)',
  },
  _links: {
    self: [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/about/31',
      },
    ],
    collection: [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/about',
      },
    ],
    about: [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/types/about',
      },
    ],
    'wp:attachment': [
      {
        href: 'https://spasmalgon.teva.ru/wp-json/wp/v2/media?parent=31',
      },
    ],
    curies: [
      {
        name: 'wp',
        href: 'https://api.w.org/{rel}',
        templated: true,
      },
    ],
  },
};
